.main {
  margin-top: var(--ads-v2-spaces-4);
}

.presets-list {
  display: flex;
  flex-wrap: wrap;
  gap: var(--ads-v2-spaces-4);
  padding-top: var(--ads-v2-spaces-2);
  margin-inline-start: var(--ads-v2-spaces-1);

  & :is(button, label) {
    height: var(--ads-v2-spaces-6);
    width: var(--ads-v2-spaces-6);
    border-radius: 50%;
    cursor: pointer;
  }

  & :is(button, label):not([data-selected]) {
    box-shadow: 0 0 0 3px var(--ads-color-black-100);
  }

  & button[data-selected] {
    box-shadow:
      0 0 0 2px white,
      0 0 0 4px currentColor;
  }

  & label {
    position: relative;
    background: conic-gradient(red, yellow, lime, aqua, blue, magenta, red);
  }

  & label input {
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
    top: 30px;
  }
}
